import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { saveLoanCalculator, updateLoanCalculation, resetCalculator} from 'actions/actions';
import breakpoint from 'styled-components-breakpoint';

import Navigation from 'components/Common/Layout/Navigation';
import Calculator from 'components/Common/Calculator/Calculator';
import Form from 'components/Common/Form/Form';

const StyledModal = styled(Modal)`
  max-width: 700px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 0;
  margin-right: 0;
  ${breakpoint('sm')` 
    margin-left: auto;
    margin-right: auto;
  `}
  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    padding: 32px 24px;
    .modal-body {
      padding: 0;
    }
  }
  .modal-footer {
    border-top: 2px solid ${props => props.theme.main.colors.neutral.line};
    justify-content: center;
    padding: 0;
    .navigation {
      padding-bottom: 8px;
      padding-top: 24px
    }
  }
`;

const StyledForm = styled(Form)`
  padding-bottom: 0;
`

export default React.memo(({ isOpen=false, toggle, onClosed }) => {
  const dispatch = useDispatch();
  const { applicationId, cifId, loanType, firstPaymentDate, accessToken, isMobile } = useSelector(state => state.reducer);
  const tempLoanCalculator = useSelector(state => state.reducer.tempLoanCalculator);
  const loanCalculator = useSelector(state => state.reducer.loanCalculator);

  const save = (e) => {
    e.preventDefault();
    if(tempLoanCalculator.paymentAmount) {
      // If the loan amount has changed, but the calculation hasn't been completed.
      if(tempLoanCalculator.loanAmountChanged) {
        dispatch(updateLoanCalculation({
          cifId: cifId,
          applicationId: applicationId,
          calculation: {
            request: {
              loanAmount: tempLoanCalculator.loanAmount, 
              term: tempLoanCalculator.term, 
              termUnits: tempLoanCalculator.termUnits, 
              paymentFrequency: tempLoanCalculator.paymentFrequency, 
              loanType: loanType,
              firstPaymentDate: firstPaymentDate
            }
          }
        }, accessToken, isMobile, true));
      }
      else {
        dispatch(saveLoanCalculator());
      }
      toggle();
    }
  }

  const cancel = () => {
    // Call the loan calculator API to update DLS with the initial loan calculation.
    dispatch(updateLoanCalculation({
      cifId: cifId,
      applicationId: applicationId,
      calculation: {
        request: {
          loanAmount: loanCalculator.loanAmount, 
          term: loanCalculator.term, 
          termUnits: loanCalculator.termUnits, 
          paymentFrequency: loanCalculator.paymentFrequency, 
          loanType: loanType,
          firstPaymentDate: firstPaymentDate
        }
      }
    }, accessToken, isMobile, true));
    toggle();
  }

  return (
    <StyledModal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
      <StyledForm>
        <ModalBody>       
          <Calculator />
        </ModalBody>
        <ModalFooter>        
          <Navigation className="navigation" buttonLabel="Update" backLabel="Cancel" onClickForward={save} onClickBack={cancel} disabled={!tempLoanCalculator.paymentAmount} displayBackIcon={true} />
        </ModalFooter>
      </StyledForm>
    </StyledModal>
  );
});