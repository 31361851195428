import React from 'react'

import Layout from 'components/Layout/Layout';
import Eligibility from 'components/Eligibility/Eligibility';
import SEO from 'components/seo';

const EligibilityPage = () => (
  <Layout>
    <SEO title="Eligibility" />
    <Eligibility />
  </Layout>
)

export default EligibilityPage
