import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';
import { format } from 'date-fns';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import addYears from 'date-fns/addYears';
import { BrowserView, MobileView } from 'react-device-detect';

import Increase from 'images/increase.inline.svg';
import Decrease from 'images/decrease.inline.svg';
import IncreaseDouble from 'images/increasedouble.inline.svg';
import DecreaseDouble from 'images/decreasedouble.inline.svg';
import Warning from 'images/warning.svg';
import Exclamation from 'images/exclamation.orange.svg';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const StyledDatePicker = styled.div`

  .react-datepicker-wrapper {
    width: 100%;
    input {
      ${props => props.theme.main.fonts.body.normal};
      border: 2px solid ${props => props.theme.main.colors.neutral.line};
      width: 100%;
      height: 48px;
      background-color: white;
      &:focus, &:active {
        outline: none;
        border: 2px solid ${props => props.theme.main.colors.secondary.base};
      }
      &.warning {
        border: 2px solid ${props => props.theme.main.colors.neutral.line};
        border-bottom: 2px solid ${props => props.theme.main.colors.validation.warning};
        background: white url(${Exclamation}) right 8px center no-repeat;
      }
      &.error {
        border: 2px solid ${props => props.theme.main.colors.neutral.line};
        border-bottom: 2px solid ${props => props.theme.main.colors.validation.error};
        background: white url(${Warning}) right 8px center no-repeat;
      }      
      padding: 8px 10px;
      &::placeholder {
        color: black;
      }   
      &::-webkit-input-placeholder {
        color: black;
      }
      &:-ms-input-placeholder {
        color: black;
      } 
      &:disabled {
        background-color: ${props => props.theme.main.colors.neutral.offwhite};
        &::placeholder {
          color: ${props => props.theme.main.colors.grey.base};
        }   
        &::-webkit-input-placeholder {
          color: ${props => props.theme.main.colors.grey.base};
        }
        &:-ms-input-placeholder {
          color: ${props => props.theme.main.colors.grey.base};
        } 
      }  
    }
  }
  .react-datepicker-popper {
    &[data-placement^='top'] {
      margin-bottom: -5px;
      margin-left: 1px;
    }
    &[data-placement^='bottom'] {
      margin-top: 1px;
      margin-left: 1px;
    }
    .react-datepicker {
      border-radius: 0;
      border: none;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      padding: 16px;
      .react-datepicker__header {
        background-color: white;
        border-radius: 0;
        border-bottom: none;
        .react-datepicker__day-names {
          ${props => props.theme.main.fonts.body.bold};
          color: black;
        }
      }
      .react-datepicker__day {
        ${props => props.theme.main.fonts.body.normal};
        color: ${props => props.theme.main.colors.secondary.dark1};
        line-height: 33px;
        width: 32px;
        height: 32px;
        margin: 0;
        &:hover {
          background-color: white;
          text-decoration: underline;
        }
      }
      .react-datepicker__day--outside-month {
        color: ${props => props.theme.main.colors.grey.dark1};
      }
      .react-datepicker__today-button {
        ${props => props.theme.main.fonts.body.normal};
        color: ${props => props.theme.main.colors.secondary.dark1};
      }
      .react-datepicker__day--selected {
        ${props => props.theme.main.fonts.body.bold};
        background-color: ${props => props.theme.main.colors.secondary.dark1};
        color: white;
        border-radius: 50%;
        line-height: 32px;
        &:hover {
          background-color: ${props => props.theme.main.colors.secondary.dark1};
          text-decoration: underline;
        }
      }
      .react-datepicker__day--keyboard-selected {
        background-color: white;
        border-radius: 0;
        text-decoration: underline;
      }
      .react-datepicker__today-button {
        border-top: none;
        background-color: white;
        font-weight: normal;
      }
      .react-datepicker__day--disabled {
        color: #C0C0C0;
        cursor: not-allowed;
      }
      .react-datepicker__triangle {
        display: none;
      }
    }
  }
`;

const MonthContainer = styled.div`
  ${props => props.theme.main.fonts.body.normal};
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
      path {
        fill: #C0C0C0;
      }
    }
  }
  svg[disabled] {
    cursor: not-allowed;
      path {
        fill: #C0C0C0;
      }
  }
  margin-bottom: 12px;
`;

const YearContainer = styled.div`
  ${props => props.theme.main.fonts.body.normal};
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
  svg[disabled] {
    cursor: not-allowed;
      path {
        fill: #C0C0C0;
      }
  }
  padding-bottom: 18px;
  border-bottom: 2px solid ${props => props.theme.main.colors.neutral.offwhite};
  margin-bottom: 16px;
`;

const ErrorMessage = styled.div`
  ${props => props.theme.main.fonts.application.helper};
  margin-top: 8px;
`;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const DateInput = ({ minDate = null, maxDate = null, name = null, onChange, onError = () => {}, value = null, disabled=false, displayWarning=false, warningMessage=''}) => {
  const [date, setDate] = useState(value || null);
  const [inputError, setInputError] = useState(false);
  const [displayInputError, setDisplayInputError] = useState(false);
  const [inputValue, setInputValue] = useState(null);

  const handleChange = (date) => {
    setInputError(false);
    onError(false);
    setDisplayInputError(false);
    setDate(date);
    onChange({target: {name: name, value: date}});
  }

  const handleDateInputChange = (event) => {    
    setDate(new Date(`${event.target.value}T00:00:00.000`));
    onChange({target: {name: event.target.name, value: new Date(`${event.target.value}T00:00:00.000`)}});
  }

  const onBlur = (event) => {    
    if(inputError) {
      if(!isNaN(Date.parse(inputValue))) {
        const inputDate = new Date(inputValue)
        if(!isBefore(inputDate, minDate) && !isAfter(inputDate, maxDate)) {
          setDate(inputDate);
          setDisplayInputError(false);
          onError(false);
          onChange({target: {name: name, value: inputDate}});
        }
        else {
          setDate(inputDate);
          setDisplayInputError(true);
          onChange({target: {name: name, value: null}});
        }
      }
      else {
        setDate(null);
        setDisplayInputError(true);
        onChange({target: {name: name, value: null}});
      }      
    }
  }

  const onInputError = () => {
    setInputError(true);
    onError(true);
  }

  const onChangeRaw = (event) => {
    setInputValue(event.target.value);
  }

  let openToDate;  
  if(isBefore(date, minDate)) {
    openToDate = minDate
  }
  else if(isAfter(date, maxDate)) {
    openToDate =  maxDate;
  }
  else {
    openToDate = date;
  }

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <StyledDatePicker>
      <BrowserView>
        <DatePicker
          id={name}
          selected={date}
          onChange={handleChange}
          onChangeRaw={onChangeRaw}
          onInputError={onInputError}
          onBlur={onBlur}
          popperModifiers={{
            computeStyle: { gpuAcceleration: false }
          }}
          fixedHeight         
          minDate={minDate}
          maxDate={maxDate}
          className={(displayWarning ? `warning` : (displayInputError ? `error`: ``))}
          placeholderText="MM/DD/YYYY"
          openToDate={openToDate}
          disabled={disabled}
          utcOffset='-7'
          renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) => {
              return (<div>
              <MonthContainer>
                <Decrease onClick={!prevMonthButtonDisabled ? decreaseMonth : null} disabled={prevMonthButtonDisabled} /> {months[date.getMonth()]} <Increase onClick={!nextMonthButtonDisabled ? increaseMonth : null} disabled={nextMonthButtonDisabled} />
              </MonthContainer>
              <YearContainer>
                <div>
                  <Decrease disabled={(isBefore(addYears(date, -1), minDate))} onClick={!isBefore(addYears(date, -1), minDate) ? () => {changeYear(date.getFullYear() - 1) } : null} />
                  <DecreaseDouble disabled={(isBefore(addYears(date, -10), minDate))} onClick={!isBefore(addYears(date, -10), minDate) ? () => {changeYear(date.getFullYear() - 10)} : null} />
                </div>
                {date.getFullYear()} 
                <div>
                  <IncreaseDouble disabled={(isAfter(addYears(date, 10), maxDate))} onClick={!isAfter(addYears(date, 10), maxDate) ? () => {changeYear(date.getFullYear() + 10)} : null} />
                  <Increase disabled={(isAfter(addYears(date, 1), maxDate))} onClick={!isAfter(addYears(date, 1), maxDate) ? () => {changeYear(date.getFullYear() + 1)} : null} />
                </div>
              </YearContainer>         
            </div>)}}
          />
      </BrowserView>
      <MobileView>
        <div className="react-datepicker-wrapper">
          <input type="date" name={name} value={date ? format(date, 'yyyy-MM-dd') : ''} min={format(minDate, 'yyyy-MM-dd')} max={format(maxDate, 'yyyy-MM-dd')} onChange={handleDateInputChange} className={(displayWarning ? `warning` : (displayInputError ? `error`: ``))} aria-invalid={(displayWarning || displayInputError)} />
        </div>
      </MobileView>
      {displayWarning ? <ErrorMessage role="alert">{warningMessage}</ErrorMessage>
       : (displayInputError ? <ErrorMessage role="alert">Please enter a date between {format(minDate, 'MMMM do yyyy')} and {format(maxDate, 'MMMM do yyyy')}</ErrorMessage> : null)}
    </StyledDatePicker>);
}

DateInput.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  value: PropTypes.instanceOf(Date),
  displayWarning: PropTypes.bool,
  warningMessage: PropTypes.string

}

export default DateInput;