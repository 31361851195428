import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row, Col, FormGroup } from 'reactstrap';
import { updateLoanCalculation, updateState } from 'actions/actions';
import Warning from 'images/warning.inline.svg';
import EditIcon from 'images/edit.inline.svg';
import { format } from 'date-fns';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import addMonths from 'date-fns/addMonths';
import isWithinInterval from 'date-fns/isWithinInterval';
import breakpoint from 'styled-components-breakpoint';
import { Element } from 'react-scroll';

import Box from 'components/Common/Layout/Box';
import Label from 'components/Common/Form/Label';
import DateInput from 'components/Common/Form/DateInput';
import Footnote from 'components/Common/Layout/Footnote';
import Helper from 'components/Common/Form/Helper';

const LoanAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 2px solid ${props => props.theme.main.colors.neutral.line};
  padding-bottom: 32px;
  margin-bottom: 24px;
  .heading {
    ${props => props.theme.main.fonts.body.bold};
    color: ${props => props.theme.main.colors.grey.base};
  }
  .amount {
    ${props => props.theme.main.fonts.application.shoutout};
    line-height: 58px;
    color: black;
    span {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

const Heading = styled.div`
  ${props => props.theme.main.fonts.body.bold};
  color: ${props => props.theme.main.colors.grey.base};
`;

const Amount = styled.div`
  ${props => props.theme.main.fonts.application.h2};
  color: black;
  span {
    font-size: 18px;
    line-height: 19px;
  }
`;

const MonthlyPayments = styled(Col)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding:0;
  padding-bottom: 16px;
  align-items: center;
  ${breakpoint('md')` 
    padding-bottom: 24px;
    align-items: center;
    border-right: 2px solid ${props => props.theme.main.colors.neutral.line};
  `}
`;

const Interest = styled(Col)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding:0;
  padding-bottom: 16px;
  align-items: center;
  border-bottom: 2px solid ${props => props.theme.main.colors.neutral.line};
  ${breakpoint('md')` 
    padding-bottom: 24px;
    align-items: center;
    border-bottom: none;
  `}
`;

const LoanLength = styled(MonthlyPayments)`
  padding-top: 24px;
  ${breakpoint('md')` 
    padding-top: 0;
    border-bottom: 2px solid ${props => props.theme.main.colors.neutral.line};
  `}
`;

const LoanInterest = styled(Interest)`
  padding-bottom: 16px;
  ${breakpoint('md')` 
    padding-bottom: 24px;
    border-bottom: 2px solid ${props => props.theme.main.colors.neutral.line};
  `}
`;

const StyledWarning = styled(Warning)`
  vertical-align: top;
  margin-top: 5px;
`;

const StyledSmallWarning = styled(Warning)`
  vertical-align: top;
  width: 10px;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 28px;
  button {
    ${props => props.theme.main.fonts.body.normal};
    color: ${props => props.theme.main.colors.secondary.dark1};
    border: none;
    background: transparent;
    &:focus {
      outline: none;
    }
    svg {
      vertical-align: baseline;
      margin-right: 4px;
    }
    &:active, &:focus, &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 38px;
  && p {
    margin-top: 0;
    margin-bottom: 22px;
  }
`;

const StyledRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`;

export default React.memo(({editClick, loanAmountChanged = false, loanTermChanged = false, onError}) => {
  const dispatch = useDispatch();
  const { cifId, applicationId, firstPaymentDate, loanType, serverDatetime, incomeAccountId, relationships, accessToken, isMobile } = useSelector(state => state.reducer);
  const loanCalculator = useSelector(state => state.reducer.loanCalculator);
  const [displayPaymentFrequencyError, setDisplayPaymentFrequencyError] = useState(false);

  let paymentFrequencyLabel = '-'

  if(loanCalculator.paymentFrequency === 'BiWeekly') {
    paymentFrequencyLabel = 'Bi-weekly';
  }
  else if(loanCalculator.paymentFrequency) {
    paymentFrequencyLabel = loanCalculator.paymentFrequency;
  }

  const minDate = addDays(new Date(serverDatetime).setHours(0,0,0,0), 1);
  const maxDate = loanCalculator.paymentFrequency === 'Monthly' ? addMonths(minDate, 1) :
    loanCalculator.paymentFrequency === 'BiWeekly' ? addWeeks(minDate, 2) : addWeeks(minDate, 1); 

  useEffect(() => {
    if(firstPaymentDate !== null && !isWithinInterval(firstPaymentDate, {start: minDate, end: maxDate})) {
      setDisplayPaymentFrequencyError(true);
      dispatch(updateState({
        firstPaymentDate: null
      }));
    }
  }, [loanCalculator.paymentFrequency]);

  const handleChange = (event) => {    
    const { name, value } = event.target;

    setDisplayPaymentFrequencyError(false);
    if(isWithinInterval(value, {start: minDate, end: maxDate})) {
      dispatch(updateLoanCalculation({
        cifId: cifId,
        applicationId: applicationId,
        calculation: {
          request: {
            loanAmount: loanCalculator.loanAmount, 
            term: loanCalculator.term, 
            termUnits: loanCalculator.termUnits, 
            paymentFrequency: loanCalculator.paymentFrequency, 
            loanType: loanType,
            firstPaymentDate: value.toDateString()
          }
        }
      }, accessToken, isMobile, true));
      dispatch(updateState({
        firstPaymentDate: value
      }));
    }
  }

  const relationship = relationships.find(relationship => relationship.accounts.find(account => account.account.id.value === incomeAccountId));
  const account = relationship ? relationship.accounts.find(account => account.account.id.value === incomeAccountId).account : null;

  return(<Box>
    <Row>
      <Col>
        <LoanAmount>
          <div className="heading">Loan amount</div>
          <div className="amount" data-testid="loanAmount">{loanCalculator.loanAmount !== null ? <div>{loanAmountChanged ? <StyledWarning /> : null}${loanCalculator.loanAmount}</div> : '-'}</div>
        </LoanAmount>
      </Col>
    </Row>
    <Row>
      <Col>
        <StyledFormGroup>
          <Element name="firstPaymentDate"></Element>
          <Label name="firstPaymentDate">Schedule your first loan payment.</Label>
          <Helper name="accountHelper" label="What account are my payments withdrawn from?" header="Payment withdrawal">
            Loan payments will be automatically withdrawn from {account ? account.product.name : ''} {incomeAccountId}.
          </Helper>   
          <p>You can schedule your first payment between <b data-testid="firstPaymentDateBetween">{format(minDate, 'MMMM do')} and {format(maxDate, 'MMMM do')}</b>. Future payments will be scheduled {paymentFrequencyLabel.toLowerCase()}, based on your first payment date. <Footnote to="footnote-2">2</Footnote></p>
          <DateInput minDate={minDate} maxDate={maxDate} name="firstPaymentDate" value={firstPaymentDate} onChange={handleChange} displayWarning={displayPaymentFrequencyError} warningMessage={`You changed your payments to ${paymentFrequencyLabel.toLowerCase()}. Please select a new date for your first payment.`} onError={onError} />
        </StyledFormGroup>
      </Col>
    </Row>
    <StyledRow>
      <MonthlyPayments md={6}>
        <Heading>{paymentFrequencyLabel} payment</Heading>
        <Amount data-testid="paymentAmount">{loanCalculator.paymentAmount !== null ? <div>{loanAmountChanged || loanTermChanged ? <StyledSmallWarning /> : null}${loanCalculator.paymentAmount.toFixed(2)}</div> : '-'}</Amount> 
      </MonthlyPayments>
      <Interest md={6}>
        <Heading>Interest <span data-testid="interestRate">{loanCalculator.interestRate}%</span> <Footnote to="footnote-1">1</Footnote></Heading>
        <Amount data-testid="totalCostOfCredit">{loanCalculator.totalCostOfCredit !== null ? <div>{loanAmountChanged || loanTermChanged ? <StyledSmallWarning />: null}${loanCalculator.totalCostOfCredit.toFixed(2)}</div> : '-'}</Amount> 
      </Interest>
    </StyledRow>
    <StyledRow>
      <LoanLength md={6}>
        <Heading>Loan length</Heading>
        <Amount data-testid="loanLength">{loanTermChanged ?  <StyledSmallWarning /> : null}{loanCalculator.term} {loanCalculator.termUnits.toLowerCase()}</Amount>
      </LoanLength>
      <LoanInterest md={6}>
        <Heading>Loan + interest</Heading>
        <Amount data-testid="paymentsTotal">{loanCalculator.paymentsTotal !== null ? <div>{loanAmountChanged || loanTermChanged ? <StyledSmallWarning /> : null}${loanCalculator.paymentsTotal.toFixed(2)}</div> : '-'}</Amount>
      </LoanInterest>
    </StyledRow>

    <Row>
      <Col>
        <EditButton>
          <button type="button" onClick={editClick} tabIndex="0" data-testid="button-editLoan"><EditIcon /> Edit Loan</button>
        </EditButton>
      </Col>
    </Row>
  </Box>);
});